

@font-face {
    font-family: 'Lexend-Regular';
    src: url('Lexend-Regular.ttf') format('truetype');
    /* Add additional font properties if needed */
}


@font-face {
    font-family: 'Lexend-SemiBold';
    src: url('Lexend-SemiBold.ttf') format('truetype');
    /* Add additional font properties if needed */
}

.gradient-div {
    background: linear-gradient(180deg, #2D3142 0%, rgba(10, 12, 20, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: 380px;
    padding: 80px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}
.gradient-div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #2D3142 0%, rgba(10, 12, 20, 0) 100%);
    z-index: 1;
  }

.image-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.background-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    opacity: 0.8;
    transition: opacity 0.5s ease;
}

.content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    z-index: 2;
    color: white; /* Set text color to white or adjust as needed */
}

.gradient-div:hover .background-image {
    opacity: 1; /* Increase opacity on hover */
}

.white-div {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
    
}

.header-text {
    /* Ligamos Associações a Engenheiros */
    width: 877px;
    height: 74px;
    display: flex;
    /* Heading/Heading 2 */

    font-family: 'Lexend-Regular', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 148%;
    /* or 71px */

    font-feature-settings: 'salt' on, 'liga' off;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.bottom-text {
    /* Engenheiro Sem Fronteiras conecta engenheiros e estudantes com associações para resolver problemas do mundo real. */


    width: 400px;
    height: 136px;

    
    /* Heading/Heading 5 */

    font-family: 'Lexend-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    /* or 34px */

    font-feature-settings: 'salt' on, 'liga' off;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.logo {
    position: absolute;
    width: 202px;
    bottom: 16px; /* Adjust the vertical position as needed */
    right: 32px; /* Adjust the horizontal position as needed */
}


.bottom-button{
    /* cta button */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 10px;

    width: 161px;
    height: 59px;

    background: #E94E1B;
    border-radius: 8px;
    color: white;

    border:none;


    font-family: 'Lexend-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    
}

.sobre-header-text{
    /* Sobre */
    position: relative;
    
    /* Heading/Heading 3 */

    font-family: 'Lexend-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    /* or 50px */

    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;

    /* Natural Color/black */

    color: #181818;
}

.sobre-bottom-text{
    position: relative;
    width: 753px;
    
    /* Subtitle/Subtitle 1 - Reguler */

    font-family: 'Lexend-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    /* or 27px */

    text-align: justify;
    font-feature-settings: 'salt' on, 'liga' off;

    /* Natural Color/black-50 */

    color: #323232;

    /* Center the text horizontally */
    left: 50%;
    transform: translateX(-50%);
}

.blue-text {
    color: #3B44F6;
}
.orange-text{
    color: #E94E1B;
}




/* Footer */

.footer-div{
    background-color: black;
    color: white;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: '100%';
}


.about-container{
    margin-top: 100px; /* Adjust the top margin as needed */
    margin-bottom: 20px;
    display: flex;
}

.assocontainer{
    display: flex;
    margin: auto;
}