.association_logo{
    height: 70px;
    margin-right: 20px;
    filter: grayscale(80%);
    transition: filter 0.3s ease;
}

.association_logo_container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 75px;
}

.association_logo:hover {
    filter: grayscale(0%);
  }

.reabilita_logo{
    height: 120px;
    margin-right: 20px;
    filter: grayscale(80%);
    transition: filter 0.3s ease;
}
.reabilita_logo:hover {
    filter: grayscale(0%);
  }