.nucleos-carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    border-radius: 20px;
    align-items: center;
    margin-bottom: 100px;

}

.nucleos-member {
    
    margin: 20px;
    text-align: center;
}

.nucleosavatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    filter: grayscale(80%);
    transition: filter 0.3s ease;
}
.nucleosavatar:hover{ 
    filter: grayscale(0%);
}

.nucleosname {
    margin-top: 10px;
    font-size: 18px;
}

.nucleosjob-title {
    margin-top: 5px;
    font-size: 14px;
    color: gray;
    text-align: center ;
}

.nucleostitled {
    font-size: 24px;
    margin-bottom: 50px;
}