.team-carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 20px;
    align-items: center;
    margin-bottom: 100px;

}

.team-member {
    width: 15%;
    margin: 20px;
    text-align: center;
}

/* Additional styles for responsiveness */


@media (max-width: 650px) {
    .team-member {
        width: 40%; /* Each member takes full width on small phones */
        margin: 5px; /* Further reduced margin */
    }
}

.avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    filter: grayscale(20%);
    transition: filter 0.3s ease;
}
.avatar:hover{ 
    filter: grayscale(0%);
}

.name {
    margin-top: 10px;
    font-size: 18px;
}

.job-title {
    margin-top: 5px;
    font-size: 14px;
    color: gray;
    text-align: center ;
}

.titled {
 
    margin-bottom: 50px;
}