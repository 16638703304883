@font-face {
    font-family: 'Lexend-Regular';
    src: url('../../../style/Lexend-Regular.ttf') format('truetype');
    /* Add additional font properties if needed */
}


@font-face {
    font-family: 'Lexend-SemiBold';
    src: url('../../../style/Lexend-SemiBold.ttf') format('truetype');
    /* Add additional font properties if needed */
}

.eng_title {
    
    margin-bottom: 20px;
}


.engineering-colleges-container {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;
    margin-top: 50px;
}


.card_group{
    margin-right: 100px;
    margin-left: 100px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
}

.college_card{
    margin-top: 20px;
    transition: filter 0.3s ease;
    filter: grayscale(30%);
    border-radius: 5; 
}

.college_card img{
    width: auto;
    height: 100%;
    object-fit: cover;
  
}
@media (max-width: 768px) {
    .card_group {
        grid-template-columns: repeat(2, 1fr);
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 5px;
        filter: grayscale(0%);
    }
}

.college_card:hover{
    filter: grayscale(0%) ;
    
}