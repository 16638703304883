.header {
    height: 47px;
    position: relative;
    width: 1278px;
}

.image{
    height: 1px;
    width: 1px;
}

.image .Logo {
    height: 46px;
    left: 0;
    object-fit: cover;
    position: fixed;
    top: 0;
    width: 143px;
}
