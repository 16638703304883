@font-face {
    font-family: 'Lexend-Regular';
    src: url('../../../style/Lexend-Regular.ttf') format('truetype');
    /* Add additional font properties if needed */
}


@font-face {
    font-family: 'Lexend-SemiBold';
    src: url('../../../style/Lexend-SemiBold.ttf') format('truetype');
    /* Add additional font properties if needed */
}

.image {
    width: auto;
    height: 100%;
    transform: scale(0.6) translateX(-0%);
    transform-origin: 0 0;
    object-fit: cover; /* Ensure the image covers the entire container */
}

.image1 {
    width: auto;
    height: 100%;
    transform: scale(0.6) translateX(5%);
    transform-origin: 0 0;
    object-fit: cover; /* Ensure the image covers the entire container */
}


.half-screen-container {
    display: flex;
    width: 100%;
    height: 100vh;
}
.half-screen-container2 {
    display: flex;
    width: 100%;
    height: 100vh;
    margin-top: -200px;
    margin-bottom: -200px;
}
.left-side {
    width: 100%;
    height: auto;
    flex: 1;
    overflow: hidden;
    position: relative;
}
  

.right-side {
    flex: 1;
    padding: 20px; /* Adjust as needed */
    box-sizing: border-box;
}
  
.app-icons {
    display: flex;
    margin-top: 20px; /* Adjust as needed */
    justify-content: center;
}

.appStore-icon {
    width: 150px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    margin-right: 2px; /* Adjust as needed */
    display: flex;
    scale: 0.9;
    transition: filter 0.3s ease;
}

.Gplay-icon {
    width: 150px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    margin-right: 5px; /* Adjust as needed */
    display: flex;
    scale: 0.8;
    transition: filter 0.3s ease;
}

.header-text{


    
    /* Headin/Heading 3 */

    font-family: 'Lexend-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    /* or 50px */

    
    font-feature-settings: 'salt' on, 'liga' off;

    /* Natural Color/black */

    color: #181818;
}

h2, p {
    font-family: 'Lexend-Regular', sans-serif; /* Apply the Lexend-Regular font to h2 and p elements */
}

h2 {
    text-align: center; /* Center h2 horizontally */
    margin-bottom: 40px;
}
p {
    text-align: justify; /* Justify the text in p */
    margin-bottom: 40px;
}



.Gplay-icon:hover {
    filter: brightness(70%); /* Darken the icon on hover */
}

.appStore-icon:hover{
    filter: brightness(70%);
}



.half-screen-container__ {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;

}

.text-side,
.image-side {
    flex: 1 0 50%; /* This means the elements will take up 50% of the container's width and won't shrink */
    margin-top: 10px;
}

.responsive-image {
    width: 100%;
    height: auto;
    max-height: 500px; /* Set a maximum height for the image */
    object-fit: cover;
}

@media (max-width: 800px) {
    .text-side,
    .image-side {
        flex: 1 0 100%; /* On small screens, the elements will take up 100% of the container's width */
    }
    .text-side {
        order: 1; /* Change the order of the text-side element */
    }
    .image-side {
        order: 2; /* Change the order of the image-side element */
    }
    .app-icons {
        order: 3; /* Change the direction of the app-icons container */
    }
}
