@font-face {
    font-family: 'Lexend-Regular';
    src: url('../../../style/Lexend-Regular.ttf') format('truetype');
    /* Add additional font properties if needed */
}


@font-face {
    font-family: 'Lexend-SemiBold';
    src: url('../../../style/Lexend-SemiBold.ttf') format('truetype');
    /* Add additional font properties if needed */
}

.projs-container{
    margin-bottom: 30px;
    border-radius: 20px;
    align-items: center;
    margin-bottom: 100px;
}
.projs_title {

    margin-bottom: 50px;

}

.project_card{
    margin-left: 30px;
    margin-right: 30px;
    border-top-right-radius: 0px;
    max-height: 300px;
    overflow: hidden;
    box-shadow: 20px;
    max-width: 200px;
    
    

}

.project_card_title{
    font-size: 14px;
    
}

.project_card_group{

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -20px;
}

.project_disc{
    font-size : 12px;
    white-space: normal;
    overflow: hidden;
    text-justify:auto;
    text-overflow: ellipsis;
    color: #4B5563;
}
